@import '@/scss/abstracts/variables.scss';
@import '@/scss/abstracts/functions.scss';
@import '@/scss/abstracts/mixins.scss';
@import '@/scss/components/buttons.scss';

.login {
  @include loginComponent;
  .form__button--forgotPassword {
    transform: translateY(-22px);
  }
}
